import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import logo1 from "../assets/logo1.png";
import logo2 from "../assets/logo1.png";
import { Link, useLocation } from "react-router-dom";

const Header = ({ openModal }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [aboutSidebarOpen, setAboutSidebarOpen] = useState(false); // State for the secondary sidebar

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);

    // Close the About Us sidebar when the mobile nav is closing
    if (mobileNavOpen) {
      setAboutSidebarOpen(false);
    }
  };

  const toggleAboutSidebar = () => {
    setAboutSidebarOpen(!aboutSidebarOpen);
  };

  const sidebarRef = useRef(null);
  const aboutSidebarRef = useRef(null);

  // Handle the sidebar animation for mobile navigation
  useEffect(() => {
    if (mobileNavOpen) {
      gsap.to(sidebarRef.current, {
        x: 0, // Slide in from the left
        duration: 0.5,
        ease: "power3.inOut",
      });
    } else {
      gsap.to(sidebarRef.current, {
        x: "-100%", // Slide out to the left
        duration: 0.5,
        ease: "power3.inOut",
      });
    }
  }, [mobileNavOpen]);

  // Handle the animation for the About Us sidebar
  useEffect(() => {
    if (aboutSidebarOpen) {
      gsap.to(aboutSidebarRef.current, {
        x: 0, // Slide in from the left
        duration: 0.5,
        ease: "power3.inOut",
      });
    } else {
      gsap.to(aboutSidebarRef.current, {
        x: "-100%", // Slide out to the left
        duration: 0.5,
        ease: "power3.inOut",
      });
    }
  }, [aboutSidebarOpen]);

  // Scroll event to add a background or change the header style
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Close all toggles on route change
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // When the route changes, close both mobile nav and about sidebar
    setMobileNavOpen(false);
    setAboutSidebarOpen(false);
  }, [location]); // This effect triggers when the route changes

  return (
    <header>
      <nav className="py-6 z-50 fixed top-0 left-0 px-4 right-0 bg-transparent">
        <div
          className={`container md:max-w-[85rem] max-w-[350px] md:mx-auto   px-8 rounded-xl  md:py-4 ${
            isScrolled
              ? "bg-white/70 backdrop-blur-md"
              : "bg-blue-50 -mt-3 -md:mt-0"
          } ${isScrolled ? "-mt-3" : "md:mx-auto"}`}
        >
          <div className="flex items-center relative ">
            <Link
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2 inline-block text-lg font-bold"
              to="/"
            >
              <img src={logo1} alt="err" className="w-[16rem]" />
            </Link>
            <div>
              <button
                onClick={toggleMobileNav}
                className="flex w-12 h-12 md:ml-0 -ml-5 items-center md:justify-center bg-transparent md:hover:bg-gray-200 rounded-md transition duration-200"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 12H21"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M3 6H21"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M3 18H21"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="hidden lg:block ml-auto">
              <div className="flex items-center">
                <Link
                  className="inline-block mr-9 text-sm font-semibold text-blue-900 hover:text-gray-900"
                  to=""
                >
                  Sign In
                </Link>
                <Link
                  className="relative group inline-block py-3 px-4 text-sm text-blue-50 bg-blue-900 rounded-md overflow-hidden transition duration-300"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                  to="#"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">Request a Demo</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div
        ref={sidebarRef}
        className={`${
          mobileNavOpen ? "block" : "hidden"
        } fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50`}
      >
        <div
          onClick={toggleMobileNav}
          className="fixed inset-0 bg-gray-800 opacity-25"
        ></div>
        <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white border-r-2 overflow-y-auto">
          <div className="flex items-center mb-16">
            <Link to="/" className="mr-auto text-2xl font-medium leading-none">
              <img src={logo2} alt="err" className="w-[16rem]" />
            </Link>
            <button onClick={toggleMobileNav}>
              <svg
                className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <ul className="mb-2">
            <li>
              <Link
                to="/about"
                className="block py-4 px-5 text-gray-900 hover:bg-blue-50 rounded-lg"
              >
                About Us
              </Link>
            </li>
            <li className="w-full">
              <button
                className="block py-4 px-5 text-gray-900 text-start hover:bg-blue-50 rounded-lg w-full"
                onClick={toggleAboutSidebar}
              >
                Products & Solutions
              </button>
            </li>
            <li>
              <Link
                className="block py-4 px-5 text-gray-900 hover:bg-blue-50 rounded-lg"
                to="/contact"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="block py-4 px-5 text-gray-900 hover:bg-blue-50 rounded-lg"
                to="/"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                className="block py-4 px-5 text-gray-900 hover:bg-blue-50 rounded-lg"
                to="/"
              >
                Articles
              </Link>
            </li>
          </ul>
          <div className="py-8 px-6 mb-6 border-t border-b border-gray-200">
            <Link
              className="flex items-center text-sm font-semibold text-blue-900 hover:text-blue-600"
              to="/"
            >
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3337 6.05833C17.2811 5.9059 17.1854 5.77202 17.0582 5.67292C16.931 5.57381 16.7777 5.51374 16.617 5.5L11.8754 4.80833L9.75038 0.499999C9.68215 0.359106 9.5756 0.240284 9.44296 0.157143C9.31031 0.074003 9.15693 0.0299072 9.00039 0.0299072C8.84384 0.0299072 8.69046 0.074003 8.55781 0.157143C8.42517 0.240284 8.31862 0.359106 8.25039 0.499999L6.12539 4.8L1.38372 5.5C1.22949 5.52192 1.08449 5.58663 0.96518 5.6868C0.845868 5.78696 0.757021 5.91856 0.708721 6.06666C0.664509 6.21139 0.660541 6.36543 0.697245 6.51224C0.733948 6.65905 0.809936 6.7931 0.917055 6.9L4.35872 10.2333L3.52539 14.9667C3.49564 15.1229 3.51121 15.2844 3.57028 15.432C3.62935 15.5797 3.72943 15.7074 3.85872 15.8C3.98474 15.8901 4.13337 15.9433 4.28793 15.9536C4.44249 15.9639 4.59686 15.9309 4.73372 15.8583L9.00039 13.6333L13.2504 15.8667C13.3673 15.9326 13.4994 15.9671 13.6337 15.9667C13.8102 15.9673 13.9824 15.9118 14.1254 15.8083C14.2547 15.7157 14.3548 15.588 14.4138 15.4404C14.4729 15.2927 14.4885 15.1312 14.4587 14.975L13.6254 10.2417L17.067 6.90833C17.1874 6.8064 17.2763 6.67242 17.3235 6.52195C17.3707 6.37149 17.3742 6.21073 17.3337 6.05833ZM12.2087 9.39166C12.111 9.48619 12.0379 9.6032 11.9957 9.73247C11.9536 9.86175 11.9438 9.99937 11.9671 10.1333L12.5671 13.625L9.43372 11.9583C9.31315 11.8941 9.17865 11.8605 9.04205 11.8605C8.90546 11.8605 8.77095 11.8941 8.65039 11.9583L5.51705 13.625L6.11705 10.1333C6.14278 9.99956 6.13371 9.86195 6.09149 9.73247C6.04928 9.603 5.97617 9.48584 5.87539 9.39166L3.10872 6.76666L6.64205 6.24166C6.77473 6.22126 6.8996 6.15404 6.99285 6.05232C7.08611 5.95059 7.14182 5.82095 7.15039 5.68333L9.00039 2.20833L10.8587 5.675C10.9237 5.80583 11.0324 5.91115 11.1698 5.97292C11.3072 6.03468 11.4652 6.0489 11.6164 6.01333L15.1504 6.53333L12.2087 9.39166Z"
                  fill="#F26924"
                ></path>
              </svg>
              <span className="ml-3">Open Support Ticket</span>
            </Link>
          </div>
          <Link
            className="block py-4 px-5 font-semibold bg-gray-900 hover:bg-blue-800 rounded-lg text-blue-50 text-center"
            to="/"
          >
            Sign In
          </Link>
        </nav>
      </div>

      {/* Secondary Sidebar (About Us) */}
      <div
        ref={aboutSidebarRef}
        className={`${
          aboutSidebarOpen ? "block" : "hidden"
        } fixed top-0 left-0 bottom-0 w-full md:ml-[28rem] max-w-4xl z-50 bg-gray-100`}
      >
        <div
          onClick={toggleAboutSidebar}
          className="fixed inset-0 bg-gray-800 opacity-25"
        ></div>
        <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white border-r-2 shadow-lg overflow-y-auto">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-5xl font-semibold leading-none">Products</h2>
            <button onClick={toggleAboutSidebar}>
              <svg
                className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="text-gray-800">
            <p>
              <span className="font-semibold">ClinicSuite</span>is a versatile
              SaaS solution designed to streamline operations for eye clinics,
              dental clinics, labs, and general clinics. It offers features like
              appointment scheduling, patient management, billing, and
              specialized tools tailored to each clinic's needs, enhancing both
              efficiency and patient care.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center w-full space-y-4 mt-12">
            <div className="bg-red-500 rounded-xl h-full w-full p-8 flex flex-col">
              <p className="text-3xl font-bold text-red-900">Dental Clinic</p>
              <p className="text-lg font-bold text-red-900">Management</p>
              <div className="flex-1 flex items-end justify-between">
                <Link
                  className="relative group inline-block py-3 px-4 text-sm text-blue-50 bg-red-900 rounded-full overflow-hidden transition duration-300"
                  to="/dental-clinic"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
                <i class="fa-duotone fa-tooth text-[4rem] text-red-900"></i>
              </div>
            </div>

            <div className="bg-yellow-500 rounded-xl h-full w-full p-8 flex flex-col">
              <p className="text-3xl font-bold text-yellow-900">
                Ophthalmology
              </p>
              <p className="text-lg font-bold text-yellow-900">Management</p>
              <div className="flex-1 flex items-end justify-between">
                <Link
                  className="relative group inline-block py-3 px-4 text-sm text-blue-50 bg-yellow-900 rounded-full overflow-hidden transition duration-300"
                  to="/eye-clinic"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
                <i class="fa-duotone fa-solid fa-eye text-[4rem] text-yellow-900"></i>
              </div>
            </div>

            <div className="bg-blue-500 rounded-xl h-full w-full p-8 flex flex-col">
              <p className="text-3xl font-bold text-blue-900">Laboratory</p>
              <p className="text-lg font-bold text-blue-900">Management</p>
              <div className="flex-1 flex items-end justify-between">
                <Link
                  className="relative group inline-block py-3 px-4 text-sm text-blue-50 bg-blue-900 rounded-full overflow-hidden transition duration-300"
                  to="/laboratory"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
                <i class="fa-duotone fa-flask-vial text-[4rem] text-blue-900"></i>
              </div>
            </div>

            <div className="bg-green-500 rounded-xl h-full w-full p-8 flex flex-col">
              <p className="text-3xl font-bold text-green-900">
                General Clinic
              </p>
              <p className="text-lg font-bold text-green-900">Management</p>
              <div className="flex-1 flex items-end justify-between">
                <Link
                  className="relative group inline-block py-3 px-4 text-sm text-blue-50 bg-green-900 rounded-full overflow-hidden transition duration-300"
                  to="/general-clinic"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <span className="relative">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
                <i class="fa-duotone fa-solid fa-syringe text-[4rem] text-green-900"></i>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
