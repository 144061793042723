import React from "react";

const Page = () => {
    return (
        <div className="space-y-4 max-w-[85rem] mx-auto px-4 md:px-6 lg:px-0 py-20 md:py-16 lg:py-28">
            <div className="bg-red-500 rounded-xl min-h-[24rem] md:min-h-[32rem] flex items-center justify-center relative p-4 md:p-8">
                <div className="flex flex-col items-start justify-start space-y-4 md:space-y-6 lg:space-y-8 w-full">
                    <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl xl:text-[12rem] leading-none font-bold text-red-900 mt-2">
                        Dental Clinic
                    </h1>
                    <p className="max-w-sm md:max-w-md lg:max-w-lg text-red-200 text-sm md:text-base">
                        Revolutionizing healthcare with a secure, cloud-based system for
                        seamless patient management and improved clinical workflows
                    </p>
                    <div className="flex flex-col sm:flex-row items-start sm:items-end justify-between w-full space-y-4 sm:space-y-0">
                        <div className="space-y-2 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
                            <button
                                className="relative group inline-block py-3 px-6 md:px-8 text-sm text-center text-blue-50 bg-red-900 rounded-md overflow-hidden transition duration-300"
                            >
                                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                <span className="relative text-center">Watch Demo</span>
                            </button>
                            <button
                                className="relative group inline-block py-3 px-6 md:px-8 text-sm text-center text-blue-50 bg-red-900 rounded-md overflow-hidden transition duration-300"
                            >
                                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                <span className="relative text-center">Contact Us</span>
                            </button>
                        </div>
                        <div className="self-end">
                            <i className="fa-solid fa-tooth text-8xl sm:text-7xl md:text-9xl text-red-900"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;