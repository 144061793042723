import React from "react";
import logo from "../assets/logo1.png";

function Footer() {
  return (
    <div className="flex flex-col items-center space-y-4 p-8 justify-center bg-blue-900 h-full rounded-3xl max-w-[85rem] mx-auto mb-20">
      <div className="bg-white h-full w-full rounded-2xl">
        <section class="py-20">
          <div class="container px-12 mx-auto">
            <div class="max-w-7xl mx-auto">
              <div class="flex flex-wrap -mx-4 pb-28">
                <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                  <div class="max-w-md mx-auto lg:mx-0">
                    <h3 class="max-w-sm font-heading text-5xl md:text-6xl font-bold text-gray-900 mb-6">
                      <span>Ready to get</span>{" "}
                      <span class="font-serif italic">started</span>
                      <span>?</span>
                    </h3>
                    <p class="max-w-sm text-gray-500 mb-16">
                      If there are questions you want to ask, we will answer all
                      your question
                    </p>
                    <div class="sm:flex mb-2 items-center">
                      <input
                        class="w-full mb-3 sm:mb-0 sm:mr-4 pb-4 bg-transparent border-b border-gray-200 text-sm text-gray-900 placeholder-gray-400 outline-none"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <button
                        class="relative group inline-block flex-shrink-0 w-full sm:w-auto py-3 px-5 text-sm font-semibold text-orange-50 bg-orange-900 rounded-full overflow-hidden"
                        type="submit"
                      >
                        <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                        <span class="relative">Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 px-4">
                  <div class="max-w-md mx-auto lg:mr-0">
                    <p class="text-2xl font-semibold text-gray-900 mb-16">
                      Maybe your question is have been answered, check this out.
                    </p>
                    <div class="pb-5 mb-5 border-b border-gray-200">
                      <a
                        class="group flex items-center justify-between"
                        href="#"
                      >
                        <span class="text-lg text-gray-500 group-hover:text-orange-900">
                          What is Coca Soft
                        </span>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5L21 12M21 12L14 19M21 12L3 12"
                              stroke="#FF460C"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                    <div class="pb-5 mb-5 border-b border-gray-200">
                      <a
                        class="group flex items-center justify-between"
                        href="#"
                      >
                        <span class="text-lg text-gray-500 group-hover:text-orange-900">
                          How can I get service from Coca Soft
                        </span>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5L21 12M21 12L14 19M21 12L3 12"
                              stroke="#FF460C"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                    <div>
                      <a
                        class="group flex items-center justify-between"
                        href="#"
                      >
                        <span class="text-lg text-gray-500 group-hover:text-orange-900">
                          What kind of service will I get
                        </span>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5L21 12M21 12L14 19M21 12L3 12"
                              stroke="#FF460C"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-10 border-t border-gray-200">
                <div class="lg:flex items-center">
                  <div class="flex mb-6 lg:mb-0 lg:mr-18 items-center">
                    <a
                      class="inline-block mr-5 hover:bg-orange-50 rounded-md p-1"
                      href="#"
                    >
                      <i class="fa-brands fa-facebook"></i>
                    </a>
                    <a
                      class="inline-block mr-5 hover:bg-orange-50 rounded-md p-1"
                      href="#"
                    >
                      <i class="fa-brands fa-whatsapp"></i>
                    </a>
                    <a
                      class="inline-block mr-5 hover:bg-orange-50 rounded-md p-1"
                      href="#"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </div>
                  <div class="flex mb-6 lg:mb-0 items-center">
                    <a
                      class="inline-block mr-4 sm:mr-10 text-sm font-semibold text-gray-500 hover:text-gray-600"
                      href="#"
                    >
                      Privacy Policy
                    </a>
                    <a
                      class="inline-block mr-4 sm:mr-10 text-sm font-semibold text-gray-500 hover:text-gray-600"
                      href="#"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      class="inline-block text-sm font-semibold text-gray-500 hover:text-gray-600"
                      href="#"
                    >
                      Support
                    </a>
                  </div>
                  <span class="inline-block ml-auto text-sm text-gray-500">
                    © All Rights Reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-blue-100 md:h-44 h-20 md:w-full w-full flex items-center justify-center rounded-2xl">
        <img src={logo} alt="" className="md:w-[34rem] w-[12rem]" />
      </div>
    </div>
  );
}

export default Footer;
