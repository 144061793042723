import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

const DemoForm = () => {
  const modalRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, scale: 0.8 },
        { opacity: 1, scale: 1, duration: 0.5, ease: "power3.out" }
      );
    } else {
      gsap.to(modalRef.current, {
        opacity: 0,
        scale: 0.8,
        duration: 0.5,
        ease: "power3.in",
        onComplete: () => setIsVisible(false),
      });
    }
  }, [isVisible]);

  const closeModal = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed  inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div
        ref={modalRef}
        className="bg-slate-50 rounded-lg md:mt-0 mt-10 shadow-lg p-4 sm:p-4 w-full max-w-5xl max-h-[100vh] overflow-auto md:overflow-hidden"
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center ">
          <i className="text-xl sm:text-2xl font-bold"></i>
          <button
            className="text-gray-500 hover:text-gray-800 p-2"
            onClick={closeModal}
          >
            X
          </button>
        </div>

        {/* Modal Body (Form) */}
        <section className="overflow-auto">
          <div className="container mx-auto">
            <div className="flex flex-wrap-reverse">
              {/* Left Column */}
              <div className="w-full lg:w-1/2 p-2">
                <div className="flex flex-col justify-between h-full max-w-xl mx-auto space-y-6">
                  <div>
                    <div className="px-2 py-3 rounded-md border border-blue-100 bg-blue-50 inline-flex items-center gap-2 mb-4">
                      <i className="fas fa-envelope text-blue-500"></i>
                      <span className="text-blue-500 text-sm font-medium">
                        Request Demo
                      </span>
                    </div>
                    <h1 className="text-2xl lg:text-4xl font-bold font-heading mb-4">
                      How can we help you today?
                    </h1>
                    <p className="text-gray-600 text-justify">
                      Our customer support team is here to assist you with
                      quick, reliable solutions. Whether it's answering
                      questions or resolving issues, we're ready to help. Expect
                      friendly service and fast responses—your satisfaction is
                      our priority!
                    </p>
                  </div>

                  <div className="bg-blue-600 rounded-lg p-4 sm:p-6">
                    <h2 className="text-white text-xl font-semibold mb-4">
                      Social Support
                    </h2>
                    <div className="flex flex-wrap gap-2">
                      <a
                        className="flex-1 py-2 px-4 h-10 rounded bg-white border border-blue-700 shadow text-sm font-semibold hover:bg-gray-50 focus:ring focus:ring-blue-200 transition duration-200 inline-flex items-center justify-center gap-2"
                        href="#"
                      >
                        <i className="fab fa-facebook-f text-blue-700"></i>
                        <span className="text-blue-900 text-sm font-semibold">
                          Facebook
                        </span>
                      </a>
                      <a
                        className="flex-1 py-2 px-4 h-10 rounded bg-white border border-blue-700 shadow text-sm font-semibold hover:bg-gray-50 focus:ring focus:ring-blue-200 transition duration-200 inline-flex items-center justify-center gap-2"
                        href="#"
                      >
                        <i className="fab fa-twitter text-blue-400"></i>
                        <span className="text-blue-900 text-sm font-semibold">
                          Twitter
                        </span>
                      </a>
                      <a
                        className="flex-1 py-2 px-4 h-10 rounded bg-white border border-blue-700 shadow text-sm font-semibold hover:bg-gray-50 focus:ring focus:ring-blue-200 transition duration-200 inline-flex items-center justify-center gap-2"
                        href="#"
                      >
                        <i className="fab fa-linkedin-in text-blue-600"></i>
                        <span className="text-blue-900 text-sm font-semibold">
                          LinkedIn
                        </span>
                      </a>
                    </div>

                    <h2 className="text-white text-xl font-semibold mt-6 mb-4">
                      Technical Support
                    </h2>
                    <a
                      className="w-full sm:w-auto py-2 px-4 h-10 rounded bg-white border border-blue-700 shadow text-sm font-semibold hover:bg-gray-50 focus:ring focus:ring-blue-200 transition duration-200 inline-flex items-center justify-center gap-2"
                      href="#"
                    >
                      <i className="fas fa-phone-alt text-blue-400"></i>
                      <span className="text-blue-900 text-sm font-semibold">
                        +92-300-111-899
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="w-full lg:w-1/2 p-4">
                <form className="rounded-lg">
                  <div className="border border-slate-100 bg-white rounded-lg p-4 sm:p-6 max-w-xl mx-auto">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full sm:w-1/2 p-2">
                        <label
                          className="text-sm font-medium mb-1 block"
                          htmlFor="textInput1"
                        >
                          First name
                        </label>
                        <input
                          className="w-full rounded p-3 outline-none bg-slate-100 border border-slate-200 placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200"
                          id="textInput1"
                          type="text"
                          placeholder="First name"
                        />
                      </div>
                      <div className="w-full sm:w-1/2 p-2">
                        <label
                          className="text-sm font-medium mb-1 block"
                          htmlFor="textInput2"
                        >
                          Last name
                        </label>
                        <input
                          className="w-full rounded p-3 outline-none border bg-slate-100 border-slate-200 placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200"
                          id="textInput2"
                          type="text"
                          placeholder="Last name"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full sm:w-1/2 p-2">
                        <label
                          className="text-sm font-medium mb-1 block"
                          htmlFor="textInput3"
                        >
                          Email
                        </label>
                        <input
                          className="w-full rounded p-3 outline-none border bg-slate-100 border-slate-200 placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200"
                          id="textInput3"
                          type="email"
                          placeholder="john@email.com"
                        />
                      </div>
                      <div className="w-full sm:w-1/2 p-2">
                        <label
                          className="text-sm font-medium mb-1 block"
                          htmlFor="textInput4"
                        >
                          Phone number
                        </label>
                        <div className="w-full rounded px-2 py-3 border bg-slate-100 border-slate-200 flex items-center">
                          <select className="outline-none bg-transparent">
                            <option value="PK">PK</option>
                            <option value="US">US</option>
                          </select>
                          <input
                            className="w-full px-2 bg-transparent outline-none placeholder-gray-500"
                            id="textInput4"
                            type="tel"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full p-2">
                      <label
                        className="text-sm font-medium mb-1 block"
                        htmlFor="textInput5"
                      >
                        Request Demo
                      </label>
                      <input
                        className="w-full rounded p-3 outline-none border bg-slate-100 border-slate-200 placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200"
                        id="textInput5"
                        type="text"
                        placeholder="Request a Demo for"
                      />
                    </div>

                    <div className="w-full p-2">
                      <label
                        className="text-sm font-medium mb-1 block"
                        htmlFor="textInput6"
                      >
                        Message
                      </label>
                      <textarea
                        className="resize-none w-full rounded p-3 mb-6 outline-none border bg-slate-100 border-slate-200 placeholder-gray-500 focus:ring focus:ring-blue-200 transition duration-200"
                        id="textInput6"
                        rows={4}
                        placeholder="Enter your message"
                      />
                    </div>

                    <button
                      className="w-full py-3 px-6 h-12 rounded inline-flex items-center justify-center text-center mb-6 bg-blue-500 border border-blue-600 font-bold font-heading text-white hover:bg-blue-600 focus:ring focus:ring-blue-200 transition duration-200"
                      type="submit"
                    >
                      Submit
                    </button>

                    <p className="text-gray-500 text-sm text-center">
                      We process your information in accordance with our{" "}
                      <span className="text-blue-500 font-semibold cursor-pointer">
                        Privacy Policy
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DemoForm;
