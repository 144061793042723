import React from "react";
import star from "../../assets/star.svg";
import logo1 from "../../assets/brand-light1.png";
import logo2 from "../../assets/brand-light2.png";
import logo3 from "../../assets/brand-light3.png";
import logo4 from "../../assets/brand-light4.png";

function Testimonial() {
  return (
    <div>
      <section class="pt-28 pb-32 mx-4 md:mx-0 bg-black rounded-xl overflow-hidden">
        <div class="container mx-auto -mt-20 md:mt-0 px-4">
          <p class="mb-16 font-heading font-semibold text-2xl md:text-6xl sm:text-7xl text-white text-center">
            Trusted by popular marketing brands
          </p>
          <div class="flex flex-wrap justify-center items-center -m-10 pb-10 border-b border-gray-800">
            <div class="w-auto p-10">
              <img src={logo1} alt="" />
            </div>
            <div class="w-auto p-10">
              <img src={logo2} alt="" />
            </div>
            <div class="w-auto p-10">
              <img src={logo3} alt="" />
            </div>
            <div class="w-auto p-10">
              <img src={logo4} alt="" />
            </div>
          </div>
          <div class="mt-24 max-w-2xl mx-auto text-center">
            <div class="flex items-center justify-center -m-1 mb-10">
              <img class="p-1" src={star} alt="" />
              <img class="p-1" src={star} alt="" />
              <img class="p-1" src={star} alt="" />
              <img class="p-1" src={star} alt="" />
            </div>
            <p class="mb-5 text-xl text-gray-300 text-justify">
              “You made it so simple. My new team is so much faster and easier
              to work with than my old site. I just choose the page, make the
              change.”
            </p>
            <p class="font-heading text-xl text-white">
              Denny Jones, founder of Growthio
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonial;
