import React from "react";

const Page = () => {
    return (
        <div className="space-y-4 max-w-[85rem] mx-auto md:py-32 pt-16">
            <div className="bg-green-500 rounded-xl min-h-[32rem] flex items-center justify-center relative p-4">
                <div className="flex items-start justify-start flex-col space-y-4">
                    <h1 className="text-5xl md:text-8xl lg:text-[12rem] leading-none font-bold text-green-900 my-2">General Clinic</h1>
                    <p className="max-w-sm text-green-900 text-sm md:text-base">
                        Revolutionizing healthcare with a secure, cloud-based system for
                        seamless patient management and improved clinical workflows
                    </p>
                    <div className="flex items-end justify-between w-full">
                        <div className="space-x-4">
                            <button
                                className="relative group inline-block py-3 px-8 text-sm text-center text-green-50 bg-green-900 rounded-md overflow-hidden transition duration-300"
                            >
                                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                <span className="relative text-center">Watch Demo</span>
                            </button>
                            <button
                                className="relative group inline-block py-3 px-8 text-sm text-center text-green-50 bg-green-900 rounded-md overflow-hidden transition duration-300"
                            >
                                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                <span className="relative text-center">Watch Demo</span>
                            </button>
                        </div>
                        <div>
                            <i className="fa-duotone fa-syringe text-[7rem] text-green-900"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
