import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import HeroContainer from "./Pages/Home/HeroContainer";
import Loader from "./components/Loader";
import Footer from "./components/Footer";
import Demo from "./components/Windows/DemoModal";
import Dental from "./Pages/DentalPage/Page";
import Eye from "./Pages/EyePage/Page";
import Laboratory from "./Pages/Lab/Page";
import GeneralClinic from "./Pages/GeneralClinic/Page";
import AboutUs from "./Pages/About/Page";
import Contact from './Pages/Contact/Page';

// Custom hook to manage modal state
const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/request-demo") {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [location]);

  const openModal = () => {
    navigate("/request-demo", { state: { background: location } });
  };

  const closeModal = () => {
    navigate(-1);
  };

  return { isModalOpen, openModal, closeModal };
};

function App() {
  const [loading, setLoading] = useState(true);
  const { isModalOpen, openModal, closeModal } = useModal();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header openModal={openModal} />
      <Routes>
        <Route path="/" element={<HeroContainer />} />
        <Route path="/dental-clinic" element={<Dental />} />
        <Route path="/eye-clinic" element={<Eye />} />
        <Route path="/laboratory" element={<Laboratory />} />
        <Route path="/general-clinic" element={<GeneralClinic />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/request-demo" element={null} /> {/* This route is just for URL matching */}
      </Routes>
      <Footer />

      {isModalOpen && <Demo onClose={closeModal} />}
    </div>
  );
}

export default App;