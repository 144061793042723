import React, { useState } from "react";
import { Link } from "react-router-dom";
import robot from "../../assets/robot.webp";
import VideoDemoModal from "../../components/Windows/VideoDemoModal";

const GridComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div className="space-y-4 pt-4">
      <div className="bg-purple-500 rounded-xl min-h-[32rem] flex items-center justify-start flex-col p-4">
        <div className="flex items-center justify-center flex-col space-y-4 mt-8 md:mt-24">
          <div className="text-center">
            <p className="px-4 py-1 rounded-full bg-purple-900 text-white inline-block text-sm md:text-base">
              Your Saas based Health Facilities Companion
            </p>
            <p className="text-5xl md:text-8xl lg:text-[12rem] leading-none font-bold text-purple-900 mt-2">
              Clinic Suite
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between w-full space-y-4 md:space-y-0 md:space-x-4">
            <p className="max-w-sm text-purple-200 text-sm md:text-base">
              Revolutionizing healthcare with a secure, cloud-based system for
              seamless patient management and improved clinical workflows
            </p>
            <div className="flex flex-col space-y-4">
              <button
                className="relative group inline-block py-3 px-8 text-sm text-center text-blue-50 bg-purple-900 rounded-md overflow-hidden transition duration-300"
                onClick={openModal}
              >
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative text-center">Watch Demo</span>
              </button>

              <button
                className="relative group inline-block py-3 px-8 text-sm text-center text-blue-50 bg-purple-900 rounded-md overflow-hidden transition duration-300"
                onClick={openModal}
              >
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative text-center">
                  Request Consulation
                </span>
              </button>
            </div>
          </div>
        </div>
        <img
          src={robot}
          alt="robot"
          className="w-48 md:w-[24rem] mt-8 md:mt-0 md:absolute md:top-72 md:bottom-0 moving-image"
        />
      </div>
      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {[
          {
            title: "Dental Clinic",
            subtitle: "Management",
            color: "red",
            icon: "fa-tooth",
            link: "/dental-clinic"
          },
          {
            title: "Ophthalmology",
            subtitle: "Management",
            color: "yellow",
            icon: "fa-eye",
            link: "/eye-clinic"
          },
          {
            title: "Laboratory",
            subtitle: "Management",
            color: "blue",
            icon: "fa-flask-vial",
            link: "/laboratory"
          },
          {
            title: "General Clinic",
            subtitle: "Management",
            color: "green",
            icon: "fa-syringe",
            link: "/general-clinic"
          },
        ].map((item, index) => (
          <div
            key={index}
            className={`bg-${item.color}-500 rounded-xl h-64 w-full p-8 flex flex-col`}
          >
            <p className={`text-3xl font-bold text-${item.color}-900`}>
              {item.title}
            </p>
            <p className={`text-lg font-bold text-${item.color}-900`}>
              {item.subtitle}
            </p>
            <div className="flex-1 flex items-end justify-between">
              <Link
                className={`relative group inline-block py-3 px-4 text-sm text-blue-50 bg-${item.color}-900 rounded-full overflow-hidden transition duration-300`}
                to={`${item.link}`}
              >
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </Link>
              <i
                className={`fa-duotone ${item.icon} text-[4rem] text-${item.color}-900`}
              ></i>
            </div>
          </div>
        ))}
      </div>

      <VideoDemoModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default GridComponent;
