import React from 'react'

function Loader() {
    return (
        <div className="max-w-7xl mx-auto flex flex-col items-center justify-center h-screen text-6xl">
            <l-cardio size="70" stroke="4" speed="2" color="#1E3A8A"></l-cardio>
        </div>
    )
}

export default Loader
