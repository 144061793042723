import React from "react";
import Steps from "../Home/Steps";
import Stats from "../Home/Stats";

function AboutUs() {
    return (
        <div className="max-w-[85rem] mx-auto py-12 sm:py-16 md:py-24 px-4 sm:px-6 lg:px-8">
            <section className="relative pt-8 sm:pt-12 md:pt-16 lg:pt-20 overflow-hidden">
                <img
                    className="absolute top-0 right-0 w-1/4 md:w-auto max-w-full"
                    src="saturn-assets/images/features/star-element-right.png"
                    alt=""
                />
                <div className="relative container mx-auto">
                    <div className="max-w-7xl mx-auto">
                        <div className="mb-8 sm:mb-12 md:mb-16">
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-gray-900 mb-4">
                                About <span className="font-bold">ClinicSuite</span>
                            </h1>
                            <p className="text-sm sm:text-base lg:text-lg text-justify">
                                ClinicSuite is a versatile SaaS solution designed to
                                streamline operations for eye clinics, dental clinics, labs,
                                and general clinics. It offers features like appointment
                                scheduling, patient management, billing, and specialized tools
                                tailored to each clinic's needs, enhancing both efficiency and
                                patient care.
                            </p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-8">
                            {[
                                { bg: "bg-red-200", icon: "fa-light fa-headset", text: "Online Support", color: "text-red-900" },
                                { bg: "bg-blue-200", icon: "fa-light fa-file-chart-column", text: "Online Support", color: "text-blue-900" },
                                { bg: "bg-green-200", icon: "fa-duotone fa-solid fa-code-branch", text: "Integration Support", color: "text-green-900" },
                                { bg: "bg-purple-200", icon: "fa-duotone fa-solid fa-arrow-down-left-and-arrow-up-right-to-center", text: "Legacy System", color: "text-purple-900" },
                                { bg: "bg-yellow-200", icon: "fa-duotone fa-solid fa-sidebar", text: "Adaptive UI", color: "text-yellow-900" },
                            ].map((item, index) => (
                                <div key={index} className={`${item.bg} rounded-lg p-4 flex flex-col items-center justify-center space-y-4 h-40 sm:h-48`}>
                                    <i className={`${item.icon} text-3xl sm:text-4xl lg:text-5xl ${item.color}`}></i>
                                    <h2 className={`text-lg sm:text-xl font-semibold ${item.color} text-center`}>{item.text}</h2>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Steps />
            <Stats />
        </div>
    );
}

export default AboutUs;