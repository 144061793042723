import React, { useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Hero from "./Hero";
import DemoModal from "../../components/Windows/DemoModal";
import AboutUs from "./AboutUs";
import Steps from "./Steps";
import Stats from "./Stats";
import Testimonial from "./Testimonial";
import CTA from "./CTA";
import FAQs from "./FAQs";

gsap.registerPlugin(ScrollTrigger);

function HeroContainer() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000); // Changed from 3000 to 2000 milliseconds (2 seconds)

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Setup ScrollTrigger animations
    const sections = gsap.utils.toArray(".animate-on-scroll");

    sections.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "bottom top",
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <div className="max-w-[85rem] mx-auto md:pt-24 pt-16 space-y-8 md:space-y-24 ">
        <Hero />
        <AboutUs />
        <Steps />
        <Stats />
        <Testimonial />
        <CTA />
        <FAQs />
      </div>
      <DemoModal isVisible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default HeroContainer;
