import React, { useState } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: 'Can I cancel at any time?',
      answer: 'Yes, you can cancel anytime. No questions asked, but we would appreciate your feedback.',
    },
    {
      question: 'My team has credits. How do we use them?',
      answer: 'Once your team signs up for a subscription plan, we’ll sit down, grab a cup of coffee, and dial in the details.',
    },
    {
      question: 'How does Preline\'s pricing work?',
      answer: 'Our subscriptions are tiered. Understanding the task at hand is key.',
    },
    {
      question: 'How secure is Preline?',
      answer: 'Protecting your data is our first priority, which is crucial to keep the project on track.',
    },
    {
      question: 'How do I get access to a theme I purchased?',
      answer: 'You can log into your account and find your purchases, or use our Redownload page with the email you used.',
    },
    {
      question: 'Upgrade License Type',
      answer: 'You can upgrade your license and apply your original purchase cost to the new license.',
    },
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid md:grid-cols-5 gap-10">
        <div className="md:col-span-2">
          <div className="max-w-xs">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">
              Frequently<br />asked questions
            </h2>
            <p className="mt-1 hidden md:block text-gray-600">
              Answers to the most frequently asked questions.
            </p>
          </div>
        </div>

        <div className="md:col-span-3">
          <div className="hs-accordion-group divide-y divide-gray-200">
            {faqs.map((faq, index) => (
              <div key={index} className={`hs-accordion pt-6 pb-3 ${openIndex === index ? 'active' : ''}`}>
                <button
                  className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={openIndex === index}
                >
                  {faq.question}
                  <svg className={`hs-accordion-active:hidden block shrink-0 size-5 text-gray-600 group-hover:text-gray-500 ${openIndex === index ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="m6 9 6 6 6-6"/>
                  </svg>
                  <svg className={`hs-accordion-active:block hidden shrink-0 size-5 text-gray-600 group-hover:text-gray-500 ${openIndex === index ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="m18 15-6-6-6 6"/>
                  </svg>
                </button>
                {openIndex === index && (
                  <div className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" role="region">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
