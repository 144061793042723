import React from "react";

const Page = () => {
  return (
    <div className="space-y-4 max-w-[85rem] mx-4 md:mx-auto md:py-32 pt-16">
      <div className="bg-yellow-500 rounded-xl min-h-[24rem] sm:min-h-[28rem] md:min-h-[32rem] flex items-center justify-center relative p-4 sm:p-6 md:p-9">
        <div className="flex flex-col items-start justify-start space-y-4 w-full">
          <h1 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-[12rem] leading-none font-bold text-yellow-900 my-2">
            Opthamology
          </h1>
          <p className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg text-yellow-900 text-sm md:text-base">
            Revolutionizing healthcare with a secure, cloud-based system for
            seamless patient management and improved clinical workflows
          </p>
          <div className="flex flex-col sm:flex-row items-start sm:items-end justify-between w-full mt-4 sm:mt-6">
            <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
              <button className="relative group inline-block py-3 px-8 text-sm text-center text-blue-50 bg-yellow-900 rounded-md overflow-hidden transition duration-300">
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative text-center">Watch Demo</span>
              </button>
              <button className="relative group inline-block py-3 px-8 text-sm text-center text-blue-50 bg-yellow-900 rounded-md overflow-hidden transition duration-300">
                <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative text-center">Get Started</span>
              </button>
            </div>
            <div className="mt-6 sm:mt-0">
              <i className="fa-solid fa-eye text-6xl sm:text-7xl md:text-[8rem] text-yellow-900"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;