import React from "react";
import star from "../../assets/star.svg";
import {Link} from 'react-router-dom';
function AboutUs() {
  return (
    <div>
      <section className="relative pt-10 pb-4 md:pt-20 overflow-hidden">
        <img
          className="absolute top-0 right-0 w-1/4 md:w-auto"
          src="saturn-assets/images/features/star-element-right.png"
          alt=""
        />
        <div className="relative container mx-auto px-4">
          <div className="max-w-8xl mx-auto">
            <div className="flex flex-wrap -mx-4 mb-12 md:mb-18 items-center">
              <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-8 lg:mb-0">
                <div>
                  <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-900 bg-blue-50 rounded-full">
                    ABOUT US
                  </span>
                  <h1 className="text-3xl xs:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-900">
                    About <span className="font-bold">ClinicSuite</span>
                  </h1>
                </div>
              </div>
              <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
                <div className="max-w-sm lg:ml-auto">
                  <p className="text-base lg:text-lg text-gray-500">
                    We are a team of 20+ who are passionate about making the
                    world a better place.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row h-full items-start justify-center space-y-4 lg:space-y-0 lg:space-x-4 mt-8">
              <div className="w-full lg:w-1/2 flex h-full flex-col items-start rounded-lg md:py-1 pr-4 lg:pr-4">
                <h1 className="text-sm md:text-base lg:text-lg p-3 text-justify">
                  ClinicSuite is a versatile SaaS solution designed to
                  streamline operations for eye clinics, dental clinics, labs,
                  and general clinics. It offers features like appointment
                  scheduling, patient management, billing, and specialized tools
                  tailored to each clinic's needs, enhancing both efficiency and
                  patient care.
                </h1>
                <div className="flex flex-col max-w-3xl mt-4 lg:mt-8 flex-1 justify-between pl-3 h-32">
                  <div className="flex mb-2">
                    {[...Array(5)].map((_, index) => (
                      <img
                        key={index}
                        className="w-4 h-4 md:w-6 md:h-6"
                        src={star}
                        alt="star"
                      />
                    ))}
                  </div>
                  <p className="text-xl md:text-2xl font-semibold mb-6 lg:mb-10">
                    With Saturn the results are very satisfying. wrapped with
                    High quality and innovative design that makes a surge of
                    visitors on my website
                  </p>
                </div>
                <Link
                  className="relative group inline-block ml-2 w-full xs:w-auto py-3 px-4 md:py-4 md:px-6 text-blue-900 hover:text-white font-semibold bg-blue-100 rounded-md overflow-hidden transition duration-500 mt-4 lg:mt-32"
                  to="/about"
                >
                  <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                  <div className="relative flex items-center justify-center">
                    <span className="mr-2 md:mr-4 text-sm md:text-base">
                      Deep dive into About Us
                    </span>
                    <span>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>

              <div className="w-full lg:w-1/4 flex flex-col items-center justify-center space-y-4">
                <div className="bg-yellow-200 rounded-xl h-64 w-full p-4 md:p-6 lg:p-8 flex flex-col">
                  <p className="text-xl md:text-2xl lg:text-3xl font-bold text-yellow-900">
                    Online
                  </p>
                  <p className="text-sm md:text-base lg:text-lg font-bold text-yellow-900">
                    Support
                  </p>
                  <div className="flex-1 flex items-end justify-between">
                    <a
                      className="relative group inline-block py-2 px-3 md:py-3 md:px-4 text-sm text-blue-50 bg-yellow-900 rounded-full overflow-hidden transition duration-300"
                      href="#"
                    >
                      <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <span className="relative">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                    <i className="fa-duotone fa-solid fa-headset text-3xl md:text-4xl lg:text-[4rem] text-yellow-900"></i>
                  </div>
                </div>

                <div className="bg-blue-200 rounded-xl h-64 w-full p-4 md:p-6 lg:p-8 flex flex-col">
                  <p className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-900">
                    Reports
                  </p>
                  <p className="text-sm md:text-base lg:text-lg font-bold text-blue-900">
                    Easily Generated
                  </p>
                  <div className="flex-1 flex items-end justify-between">
                    <a
                      className="relative group inline-block py-2 px-3 md:py-3 md:px-4 text-sm text-blue-50 bg-blue-900 rounded-full overflow-hidden transition duration-300"
                      href="#"
                    >
                      <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <span className="relative">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                    <i className="fa-duotone fa-solid fa-file-chart-column text-3xl md:text-4xl lg:text-[4rem] text-blue-900"></i>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-1/4 flex flex-col items-center justify-center">
                <div className="bg-green-200 rounded-xl h-64 lg:h-[530px] w-full p-4 md:p-6 lg:p-8 flex flex-col">
                  <p className="text-xl md:text-2xl lg:text-3xl font-bold text-green-900">
                    Integration
                  </p>
                  <p className="text-sm md:text-base lg:text-lg font-bold text-green-900">
                    Support & Management
                  </p>
                  <div className="flex-1 flex items-end justify-between">
                    <a
                      className="relative group inline-block py-2 px-3 md:py-3 md:px-4 text-sm text-blue-50 bg-green-900 rounded-full overflow-hidden transition duration-300"
                      href="#"
                    >
                      <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <span className="relative">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                    <i className="fa-duotone fa-solid fa-code-branch text-3xl md:text-4xl lg:text-[4rem] text-green-900"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex items-center md:flex-nowrap flex-wrap justify-center md:px-4 md:space-x-4">
        <div className="bg-purple-200 md:w-1/2 w-full rounded-xl h-64 md:mx-0 mx-4 md:mb-0 mb-2 lg:h-64 p-6 md:p-8 flex flex-col">
          <p className="text-2xl md:text-3xl font-bold text-purple-900">
            Legacy System
          </p>
          <p className="text-base md:text-lg font-bold text-purple-900">
            Migration
          </p>
          <div className="flex-1 flex items-end justify-between">
            <a
              className="relative group inline-block py-2 px-3 md:py-3 md:px-4 text-sm text-blue-50 bg-purple-900 rounded-full overflow-hidden transition duration-300"
              href="#"
            >
              <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
              <span className="relative">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </a>
            <i class="fa-duotone fa-solid fa-arrow-down-left-and-arrow-up-right-to-center text-2xl md:text-[4rem]  text-purple-900"></i>
          </div>
        </div>
        <div className="bg-red-200 rounded-xl h-64 lg:h-64 w-full p-6 md:p-8 flex flex-col md:mx-0 mx-4">
          <p className="text-2xl md:text-3xl font-bold text-red-900">
            User Friendly & Easy
          </p>
          <p className="text-base md:text-lg font-bold text-red-900">
            Interface
          </p>
          <div className="flex-1 flex items-end justify-between">
            <a
              className="relative group inline-block py-2 px-3 md:py-3 md:px-4 text-sm text-blue-50 bg-red-900 rounded-full overflow-hidden transition duration-300"
              href="#"
            >
              <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
              <span className="relative">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </a>
            <i className="fa-duotone fa-solid fa-sidebar md:text-[4rem] text-2xl  text-red-900"></i>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
