import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import videoDemo from '../../assets/demo.mp4';

function VideoDemoModal({ isVisible, onClose }) {
    const modalRef = useRef(null);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true); // Set initial state to true for autoplay

    useEffect(() => {
        if (isVisible) {
            gsap.fromTo(
                modalRef.current,
                { opacity: 0, scale: 0.8 },
                { opacity: 1, scale: 1, duration: 0.5, ease: "power3.out" }
            );

            // Play the video when the modal is visible
            if (videoRef.current) {
                videoRef.current.play();
            }
        } else {
            gsap.to(modalRef.current, {
                opacity: 0,
                scale: 0.8,
                duration: 0.5,
                ease: "power3.in",
                onComplete: onClose,
            });

            // Pause the video when the modal is closed
            if (videoRef.current) {
                videoRef.current.pause();
            }
        }
    }, [isVisible, onClose]);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] bg-opacity-75 backdrop-blur-sm flex justify-center items-center'>
            <div ref={modalRef} className="relative bg-white rounded-xl shadow-sm pointer-events-auto w-[800px] py-8 h-[450px]"> {/* Increased size */}
                {/* Close Button */}
                <button 
                    onClick={onClose} 
                    className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>

                {/* Video Element */}
                <video 
                    ref={videoRef} 
                    className="w-full h-full rounded-xl" // Set to full size
                    autoPlay 
                    muted
                >
                    <source src={videoDemo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default VideoDemoModal;
