import React from "react";

function Stats() {
  return (
    <div className="flex flex-col lg:flex-row items-start justify-center lg:space-x-8 xl:space-x-24 px-4 lg:px-8">
      <div className="flex flex-col items-start justify-start text-left w-full lg:w-full mb-8 lg:mb-0">
        <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-900 bg-blue-50 rounded-full">
          Our Values
        </span>
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-900">
          Umatched <span className="font-bold">Values</span>
        </h1>
        <p className="my-4 lg:my-8 text-sm sm:text-base text-justify">
          We Offer Highly-Accessible Mental Health Counseling Services And
          Programs For Individuals And Families Aimed At Putting You On A Path
          To Wellness. You Deserve To Live Well, Feel Capable In The Face Of
          Challenges, And Achieve Your True Potential. It Starts Here
        </p>

        <div className="flex items-center flex-1 justify-between w-full space-x-2 sm:space-x-4">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="flex flex-col items-center">
              <p className="text-sm sm:text-base lg:text-xl font-semibold">
                Users
              </p>
              <h1 className="text-3xl sm:text-5xl mx-1 lg:text-7xl font-bold text-emerald-500">
                1500+
              </h1>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-start justify-start space-y-5 mt-8 w-full lg:w-1/2">
        {[
          { bg: "yellow", color: "yellow", icon: "hands-holding-heart", text: "Compassion" },
          { bg: "green", color: "green", icon: "puzzle", text: "Integrity" },
          { bg: "red", color: "red", icon: "lightbulb", text: "Excellence" },
          { bg: "blue", color: "blue", icon: "handshake", text: "Respect" },
        ].map((item, index) => (
          <div
            key={index}
            className={`flex items-center justify-start sm:justify-center space-x-4 py-3 sm:py-4 px-4 sm:px-8 rounded-xl bg-${item.bg}-200 w-full`}
          >
            <i
              className={`fa-duotone fa-solid fa-${item.icon} text-2xl sm:text-3xl lg:text-[3rem] text-${item.bg}-900`}
            ></i>
            <h1
              className={`text-2xl sm:text-3xl lg:text-4xl text-${item.color}-800 font-bold`}
            >
              {item.text}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Stats;
